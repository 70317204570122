import { ERROR_MESSAGES } from "../constants/errorConstants";

export const handleAxiosError = (error) => {
  if (!error.response) {
    return ERROR_MESSAGES.NETWORK_ERROR;
  }

  const status = error.response.status;
  return ERROR_MESSAGES[status] || ERROR_MESSAGES.DEFAULT;
};
